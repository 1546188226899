/* ============================================================ */
/* OVERRIDES */
.pagination .page-item.active a.page-link {
  pointer-events: none; }

a {
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    text-decoration: none;
    cursor: pointer; }
  a.disabled, a[disabled='disabled'] {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
    filter: alpha(opacity=50); }

.form-group label {
  margin-bottom: 0.2rem; }

.input-group .btn-light {
  border-color: #ced4da; }

.form-check-input {
  margin-top: 0.2rem; }

.invalid-feedback,
.valid-feedback {
  font-size: 1rem; }

b {
  font-weight: 600; }

code,
kbd {
  padding: 2px 4px;
  font-size: 0.9rem; }

.tooltip-inner {
  font-size: 1rem; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace; }

code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 1px;
  white-space: nowrap; }

kbd {
  color: #fff;
  background-color: #333;
  border-radius: 0;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    box-shadow: none; }

pre {
  overflow: auto;
  display: block;
  padding: 9px;
  margin: 0 0 9.5px;
  font-size: 12px;
  line-height: 1.49;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #eaeef0;
  border: 1px solid #cbd6d6;
  border-radius: 1px; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0; }

.badge {
  line-height: 1rem;
  padding: 0.2em 0.6em;
  font-size: 0.769rem;
  border-radius: 100px;
  border: 0;
  font-weight: 600;
  text-decoration: none !important; }

.dropdown-menu {
  padding: 5px; }
  .dropdown-menu .dropdown-item {
    padding: 0.3rem 0.8rem;
    border-radius: 0.25rem; }
  .dropdown-menu .dropdown-header {
    padding: 0.3rem 0.8rem; }

.btn-sm,
.form-control-sm,
.custom-select-sm {
  font-size: 0.923rem;
  height: calc(1.8125rem + 2px); }

.btn-sm {
  font-weight: 600;
  white-space: nowrap; }

.btn-light {
  border-color: #e2e6ea; }

.list-group-item-action {
  cursor: pointer; }

.list-group-item.selected {
  background-color: #fffbef; }
  .list-group-item.selected:hover {
    background-color: #fff4d2; }

/* table */
.table tbody tr.table-muted {
  opacity: 0.5; }

.table tbody tr.table-dimmed {
  opacity: 0.3; }

.table .custom-control {
  margin: 0; }

.table thead tr th {
  padding-top: 6px;
  padding-bottom: 6px;
  vertical-align: middle !important;
  white-space: nowrap;
  font-weight: 600; }

.table thead.thead-light tr th {
  font-weight: 400;
  border-bottom: 0;
  background: #f3f3f5; }

.table thead.thead-sticky tr th {
  top: -1px;
  position: sticky;
  z-index: 900; }

.table thead.thead-sticky.thead-sticky-page-header tr th {
  top: 49px;
  position: sticky;
  z-index: 900; }

.table td,
.table th {
  vertical-align: middle; }

.table td[contenteditable='true']:focus,
.table td[contenteditable='true']:hover {
  background-color: #f0f4f9; }

.table-semiborder tbody td {
  border-left: 1px solid #f2f2f2; }

.table-semiborder tbody td:first-child {
  border-left: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8; }
  .table-striped tbody tr:nth-of-type(odd):hover {
    background-color: #fff4d2; }

.table-hover tbody tr:hover {
  background-color: #f5f5f5; }

.table tbody tr.selected td {
  background-color: #fffbef; }

.table tbody tr.selected:hover td {
  background-color: #fff4d2; }

.table tbody tr.selected td {
  border-top-color: #ffdb71;
  border-bottom: 1px solid #ffdb71;
  margin-bottom: -1px; }

.page-tabs {
  background: #f9f9f9;
  padding: 0 1.5rem;
  margin-bottom: 0 !important; }

/* nav tabs*/
.nav-tabs {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 20px; }
  .nav-tabs .nav-item a {
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 0.8rem 0;
    margin-right: 2rem;
    color: #999; }
    .nav-tabs .nav-item a:focus, .nav-tabs .nav-item a:hover {
      color: #333;
      border-color: transparent; }
    .nav-tabs .nav-item a.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
    .nav-tabs .nav-item a.active {
      color: #333;
      background-color: transparent !important;
      border-bottom-color: #333; }

.modal-content > .nav-tabs {
  background: #f9f9f9;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0; }

/* angular-ui-notification */
.ui-notification {
  font-size: 12px;
  position: fixed;
  width: auto;
  max-width: 300px !important;
  min-width: 200px;
  -webkit-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  color: #fff;
  border-radius: 2px;
  background: #3498db;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #2980b9;
  display: inline-block !important; }
  .ui-notification.clickable {
    cursor: pointer; }
    .ui-notification.clickable:hover {
      opacity: 0.8; }
  .ui-notification.killed {
    -webkit-transition: opacity ease 1s;
    -o-transition: opacity ease 1s;
    transition: opacity ease 1s;
    opacity: 0; }
  .ui-notification > h3 {
    font-size: 13px;
    font-weight: 600;
    display: block;
    margin: 10px 10px -5px;
    padding: 0 0 5px;
    text-align: left;
    border: none; }
  .ui-notification a {
    color: inherit;
    text-decoration: underline; }
    .ui-notification a:hover {
      text-decoration: none; }
  .ui-notification > .message {
    margin: 10px; }
  .ui-notification.warning {
    color: #333;
    background: #f1c40f;
    border-color: #f39c12; }
  .ui-notification.error {
    color: #fff;
    background: #e74c3c;
    border-color: #c0392b; }
  .ui-notification.success {
    color: #fff;
    background: #27ae60;
    border-color: #268f52; }
  .ui-notification.info {
    color: #fff;
    background: #1abc9c;
    border-color: #16a085; }
  .ui-notification.loading {
    color: #fff;
    background: #333;
    border-color: #000; }
  .ui-notification img.icon {
    margin: -10px;
    margin-right: 10px;
    height: 60px; }
  .ui-notification.system_noti {
    color: #333;
    background: #fff;
    border: 0 !important;
    font-size: 13px;
    font-weight: 600; }
  .ui-notification .fa {
    margin-right: 5px; }

/* daterangepicker */
.daterangepicker {
  font-size: 12px;
  border-color: #aaa;
  z-index: 90000 !important; }

/* cards */
.card {
  margin-bottom: 10px; }
  .card .card-body {
    padding: 15px; }
  .card .table {
    margin-bottom: 0;
    border-style: hidden;
    border-collapse: collapse; }
    .card .table thead tr:first-child th:first-child {
      border-top-left-radius: 0.25rem; }
    .card .table thead tr:first-child th:last-child {
      border-top-right-radius: 0.25rem; }
    .card .table tr:last-child td:first-child {
      border-bottom-left-radius: 0.25rem; }
    .card .table tr:last-child td:last-child {
      border-bottom-right-radius: 0.25rem; }

/* cards */
.card {
  margin-bottom: 1rem; }
  .card .card-body {
    padding: 15px; }
    .card .card-body .divider {
      margin-top: 15px;
      margin-bottom: 15px; }
      .card .card-body .divider.divider-flush {
        margin-left: -15px;
        margin-right: -15px; }
  .card .table {
    margin-bottom: 0;
    border-style: hidden;
    border-collapse: collapse; }
    .card .table thead tr:first-child th:first-child {
      border-top-left-radius: 0.25rem; }
    .card .table thead tr:first-child th:last-child {
      border-top-right-radius: 0.25rem; }
    .card .table tr:last-child td:first-child {
      border-bottom-left-radius: 0.25rem; }
    .card .table tr:last-child td:last-child {
      border-bottom-right-radius: 0.25rem; }
  .card.card-float-header {
    margin-top: 2.5rem;
    border-width: 2px !important; }
    .card.card-float-header:first-child {
      margin-top: 0; }
    .card.card-float-header .card-header {
      font-size: 1.3rem;
      background: #fff;
      border-bottom: none;
      font-family: 'LatoWebBlack', sans-serif;
      font-weight: 600;
      position: absolute;
      top: -1.375rem;
      left: 1rem;
      margin-left: -0.5rem;
      padding: 0 0.5rem; }
      .card.card-float-header .card-header [data-toggle='collapse']:before {
        float: right !important;
        font-family: icomoon;
        content: '\e9c2';
        padding-left: 1rem;
        color: #999; }
      .card.card-float-header .card-header [data-toggle='collapse'].collapsed:before {
        float: right !important;
        font-family: icomoon;
        content: '\e9c1'; }
      .card.card-float-header .card-header [data-toggle='collapse']:hover,
      .card.card-float-header .card-header [data-toggle='collapse']:active,
      .card.card-float-header .card-header [data-toggle='collapse']:focus {
        text-decoration: none; }

[data-toggle='collapse'] .collapse-toggler:before {
  float: right !important;
  font-family: icomoon;
  content: '\e9c2';
  padding-left: 1rem;
  color: #999; }

[data-toggle='collapse'].collapsed .collapse-toggler:before {
  float: right !important;
  font-family: icomoon;
  content: '\e9c1'; }

[data-toggle='collapse'] .collapse-toggler:hover,
[data-toggle='collapse'] .collapse-toggler:active,
[data-toggle='collapse'] .collapse-toggler:focus {
  text-decoration: none; }

/* modal box overrides */
.modal-content {
  border: 2px solid black; }

.pagination {
  margin-bottom: 0; }
