.tasks {
    .task-item {
        margin-bottom: 5px;
        border-radius: .25rem !important;
        min-height: 52px;

        .block-header h3 {
            margin-top: 2px;
            font-size: 1.25rem
        }
    }

    .task-done {
        opacity: 0.5;
        background: #eee;

        .block-header h3 {
            text-decoration: line-through;
        }
    }
}

#task-detail {
    .checklist-done {
        opacity: 0.5;
        background: #eee;
        text-decoration: line-through;
    }

    .task-done {
        text-decoration: line-through;
    }
}

.task-tag {
    display: inline-block;
    padding: 2px 10px;
    padding-top: 0px;
    border-radius: 50px;
    color: #fff;
}
