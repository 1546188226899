/* ============================================================ */
/* OVERRIDES */
.pagination .page-item.active a.page-link {
  pointer-events: none;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
  }

  &.disabled,
  &[disabled='disabled'] {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
}

.form-group label {
  margin-bottom: 0.2rem;
}

.input-group {
  .btn-light {
    border-color: #ced4da;
  }
}

.form-check-input {
  margin-top: 0.2rem;
}

.invalid-feedback,
.valid-feedback {
  font-size: 1rem;
}

b {
  font-weight: 600;
}

code,
kbd {
  padding: 2px 4px;
  font-size: 0.9rem;
}

.tooltip-inner {
  font-size: 1rem;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}

code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 1px;
  white-space: nowrap;
}

kbd {
  color: #fff;
  background-color: #333;
  border-radius: 0;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);

  kbd {
    padding: 0;
    font-size: 100%;
    box-shadow: none;
  }
}

pre {
  overflow: auto;
  display: block;
  padding: 9px;
  margin: 0 0 9.5px;
  font-size: 12px;
  line-height: 1.49;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #eaeef0;
  border: 1px solid #cbd6d6;
  border-radius: 1px;

  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}

.badge {
  line-height: 1rem;
  padding: 0.2em 0.6em;
  font-size: 0.769rem;
  border-radius: 100px;
  border: 0;
  font-weight: 600;
  text-decoration: none !important;
}

.dropdown-menu {
  padding: 5px;
  // font-size: 12px;

  .dropdown-item {
    padding: 0.3rem 0.8rem;
    border-radius: 0.25rem;
  }

  .dropdown-header {
    padding: 0.3rem 0.8rem;
  }
}

.btn-sm,
.form-control-sm,
.custom-select-sm {
  font-size: 0.923rem;
  height: calc(1.8125rem + 2px);
}

.btn-sm {
  font-weight: 600;
  white-space: nowrap;
}

.btn-light {
  border-color: #e2e6ea;
}

.list-group-item-action {
  cursor: pointer;
}

.list-group-item {
  &.selected {
    background-color: #fffbef;

    &:hover {
      background-color: #fff4d2;
    }
  }
}

/* table */
// .table-responsive {
//   overflow-x: visible;
// }

.table {
  // font-size: .923rem;

  tbody tr.table-muted {
    opacity: 0.5;
  }
  tbody tr.table-dimmed {
    opacity: 0.3;
  }

  .custom-control {
    margin: 0;
  }

  thead tr th {
    padding-top: 6px;
    padding-bottom: 6px;
    vertical-align: middle !important;
    white-space: nowrap;
    font-weight: 600;
  }

  thead {
    &.thead-light tr th {
      font-weight: 400;
      border-bottom: 0;
      background: #f3f3f5;
    }
    &.thead-sticky tr th {
      top: -1px;
      position: sticky;
      z-index: 900;
    }

    &.thead-sticky.thead-sticky-page-header tr th {
      top: 49px;
      position: sticky;
      z-index: 900;
    }
  }

  td,
  th {
    vertical-align: middle;
  }

  td[contenteditable='true']:focus,
  td[contenteditable='true']:hover {
    background-color: #f0f4f9;
  }
}

.table-semiborder {
  tbody {
    td {
      border-left: 1px solid #f2f2f2;
    }
    td:first-child {
      border-left: 0;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;

  &:hover {
    background-color: #fff4d2;
  }
}

.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}

.table tbody tr.selected {
  td {
    background-color: #fffbef;
  }

  &:hover td {
    background-color: #fff4d2;
  }

  td {
    border-top-color: #ffdb71;
    border-bottom: 1px solid #ffdb71;
    margin-bottom: -1px;
  }

  // &:last-child td {
  //   border-bottom: 0;
  // }
}

.page-tabs {
  background: #f9f9f9;
  padding: 0 1.5rem;
  margin-bottom: 0 !important;
}
/* nav tabs*/
.nav-tabs {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 20px;

  .nav-item a {
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 0.8rem 0;
    margin-right: 2rem;
    color: #999;

    &:focus,
    &:hover {
      color: #333;
      border-color: transparent;
    }

    &.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent;
    }

    &.active {
      color: #333;
      background-color: transparent !important;
      border-bottom-color: #333;
    }
  }
}

.modal-content > .nav-tabs {
  background: #f9f9f9;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0;
}

/* angular-ui-notification */

.ui-notification {
  font-size: 12px;
  position: fixed;
  width: auto;
  max-width: 300px !important;
  min-width: 200px;
  -webkit-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  color: #fff;
  border-radius: 2px;
  background: #3498db;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #2980b9;
  display: inline-block !important;

  &.clickable {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &.killed {
    -webkit-transition: opacity ease 1s;
    -o-transition: opacity ease 1s;
    transition: opacity ease 1s;
    opacity: 0;
  }

  > h3 {
    font-size: 13px;
    font-weight: 600;
    display: block;
    margin: 10px 10px -5px;
    padding: 0 0 5px;
    text-align: left;
    border: none;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  > .message {
    margin: 10px;
  }

  &.warning {
    color: #333;
    background: #f1c40f;
    border-color: #f39c12;
  }

  &.error {
    color: #fff;
    background: #e74c3c;
    border-color: #c0392b;
  }

  &.success {
    color: #fff;
    background: #27ae60;
    border-color: #268f52;
  }

  &.info {
    color: #fff;
    background: #1abc9c;
    border-color: #16a085;
  }

  &.loading {
    color: #fff;
    background: #333;
    border-color: #000;
  }

  img.icon {
    margin: -10px;
    margin-right: 10px;
    height: 60px;
  }

  &.system_noti {
    color: #333;
    background: #fff;
    border: 0 !important;
    font-size: 13px;
    font-weight: 600;
  }

  .fa {
    margin-right: 5px;
  }
}

/* daterangepicker */

.daterangepicker {
  font-size: 12px;
  border-color: #aaa;
  z-index: 90000 !important;
}

/* cards */
.card {
  margin-bottom: 10px;

  .card-body {
    padding: 15px;
  }

  .table {
    thead tr:first-child th {
      &:first-child {
        border-top-left-radius: 0.25rem;
      }

      &:last-child {
        border-top-right-radius: 0.25rem;
      }
    }

    tr:last-child td {
      &:first-child {
        border-bottom-left-radius: 0.25rem;
      }

      &:last-child {
        border-bottom-right-radius: 0.25rem;
      }
    }

    margin-bottom: 0;
    border-style: hidden;
    border-collapse: collapse;
  }
}

/* cards */
.card {
  margin-bottom: 1rem;

  .card-body {
    padding: 15px;

    .divider {
      margin-top: 15px;
      margin-bottom: 15px;

      &.divider-flush {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }

  .table {
    thead tr:first-child th {
      &:first-child {
        border-top-left-radius: 0.25rem;
      }

      &:last-child {
        border-top-right-radius: 0.25rem;
      }
    }

    tr:last-child td {
      &:first-child {
        border-bottom-left-radius: 0.25rem;
      }

      &:last-child {
        border-bottom-right-radius: 0.25rem;
      }
    }

    margin-bottom: 0;
    border-style: hidden;
    border-collapse: collapse;
  }

  &.card-float-header {
    margin-top: 2.5rem;
    border-width: 2px !important;

    &:first-child {
      margin-top: 0;
    }

    .card-header {
      font-size: 1.3rem;
      background: #fff;
      border-bottom: none;
      font-family: 'LatoWebBlack', sans-serif;
      font-weight: 600;
      position: absolute;
      top: -1.375rem;
      left: 1rem;
      margin-left: -0.5rem;
      padding: 0 0.5rem;

      & [data-toggle='collapse']:before {
        float: right !important;
        font-family: icomoon;
        content: '\e9c2';
        padding-left: 1rem;
        color: #999;
      }

      & [data-toggle='collapse'].collapsed:before {
        float: right !important;
        font-family: icomoon;
        content: '\e9c1';
      }

      & [data-toggle='collapse']:hover,
      & [data-toggle='collapse']:active,
      & [data-toggle='collapse']:focus {
        text-decoration: none;
      }
    }
  }
}

// collapse has toggler
[data-toggle='collapse'] .collapse-toggler:before {
  float: right !important;
  font-family: icomoon;
  content: '\e9c2';
  padding-left: 1rem;
  color: #999;
}

[data-toggle='collapse'].collapsed .collapse-toggler:before {
  float: right !important;
  font-family: icomoon;
  content: '\e9c1';
}

[data-toggle='collapse'] .collapse-toggler:hover,
[data-toggle='collapse'] .collapse-toggler:active,
[data-toggle='collapse'] .collapse-toggler:focus {
  text-decoration: none;
}

/* modal box overrides */
.modal-content {
  border: 2px solid rgb(0, 0, 0);
}

.pagination {
  margin-bottom: 0;
}
