/* Webfont: Lato-Black */
@font-face {
  font-family: 'LatoWebBlack';
  src: url('../assets/fonts/Lato/Lato-Black.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../assets/fonts/Lato/Lato-Black.woff2') format('woff2'), /* Modern Browsers */ url('../assets/fonts/Lato/Lato-Black.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Lato/Lato-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
