.ui-block {
  overflow: hidden;
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    width: 100%;
    content: 'Loading...';
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
  }
}
textarea.cke_source {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 1rem;
  color: #000 !important;
}

.badge-outline-tag {
  border: 1px solid #e4e4e4 !important;
  background: #f9f9f9 !important;
  color: #666;
}

.upload-btn {
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }
}

a.link-icon-reveal {
  i.icon {
    display: none;
    transition: all 0.5s linear;
  }

  &:hover i.icon {
    display: inline-block;
  }
}

.noti-item.seen {
  opacity: 0.5;
}

.btn-blank {
  background: transparent !important;

  &:hover,
  &:focus,
  &.active {
    background: #efefef !important;
  }
}

.btn-loading {
  position: relative;
  background-color: #fff !important;
  border-color: #999 !important;
  color: #333 !important;

  &:after {
    cursor: wait !important;
    animation: btn-loading 1s linear infinite;
    background: repeating-linear-gradient(-60deg, rgba(white, 0.15), rgba(white, 0.15) 1em, rgba(black, 0.15) 1em, rgba(black, 0.15) 2em /* determines size */);
    background-size: 200%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @keyframes btn-loading {
      from {
        background-position: -4em 0;
      }
      to {
        background-position: -1.7em 0;
      }
    }
  }
}

.btn-sb {
  background-color: #ffe4bb;
  color: #000;
  border-color: #e8900d;

  &:hover,
  &:focus,
  &:hover {
    background-color: #ffc297;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(247, 204, 174);
  }

  &[disabled='disabled'],
  &:disabled {
    background-color: #fdc26a;
  }
}

.btn-grey {
  background-color: #f2f2f2;
  color: #000;
  border-color: #999;

  &:hover,
  &:focus,
  &:hover {
    background-color: #ddd;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #dee2e6;
  }

  &[disabled='disabled'],
  &:disabled {
    color: #666;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.text-sb {
  color: #d78321 !important;
}

.text-strike {
  text-decoration: line-through;
}

.divider {
  display: block;
  border-top: 1px solid #e4e4e4;
  margin: 10px 0;

  &.block-divider {
    margin: 30px 0;
  }
}

.icon-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

/* ============================================================ */
/* EXTENDS */
/* ============================================================ */

/* font-weights */

.fw400 {
  font-weight: 400 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

/* fontsize */

.fs10 {
  font-size: 10px !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs16 {
  font-size: 16px !important;
}

.upload-btn {
  position: relative;
  overflow: hidden;

  input.upload-input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

/* ---------------------------------------------------
    FOOTER ACTION BAR
----------------------------------------------------- */

.footer-action-bar {
  background: #f2f2f2;
  padding: 10px 20px;
  border-top: 2px solid #ddd;
  position: fixed;
  bottom: 0;
  left: 250px;
  right: 0;
  margin-bottom: -45px;
  height: 45px;
  transition: margin 0.2s;

  .actions {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      padding: 0;
      margin: 0;
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid #ccc;
      font-weight: 600;

      &:last-child {
        border: none;
      }
    }
  }

  &.show {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .footer-action-bar {
    left: 0;
  }
}

.btn_loading_icon {
  float: left;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-left: 1px solid currentColor;
  border-right: 1px solid currentColor;
  -webkit-animation: circle 0.7s infinite linear;
  animation: circle 0.7s infinite linear;
  content: ' ';
  margin-right: 8px;
  margin-top: 1px;
}

@-webkit-keyframes circle {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes circle {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.col-border-left {
  border-left: 1px solid #e4e4e4;

  &:first-child {
    border-left: none;
  }
}

@media (max-width: 768px) {
  .col-border-left {
    border-left: 0;
  }
}

/* option list */

.opt-list {
  display: inline-block;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;

    &:first-child:before {
      display: none;
    }

    &:before {
      content: '\2219';
      padding-right: 5px;
      padding-left: 3px;
    }
  }
}

.limit-text {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ------------------------------------ */
/* Avatar with Initials */

.avatar-initials {
  --avatar-size: 32px;
  --avatar-color-r: 250;
  --avatar-color-g: 250;
  --avatar-color-b: 250;
  --avatar-bg-opacity: 0.2;
  border-radius: 50%;
  height: var(--avatar-size);
  width: var(--avatar-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(var(--avatar-color-r), var(--avatar-color-g), var(--avatar-color-b), var(--avatar-bg-opacity)), rgba(var(--avatar-color-r), var(--avatar-color-g), var(--avatar-color-b), var(--avatar-bg-opacity))),
    linear-gradient(white, white) !important;
  position: relative;

  &.big {
    --avatar-size: 64px;
  }

  &.med {
    --avatar-size: 48px;
  }

  &.mid {
    --avatar-size: 24px;
  }

  &.small {
    --avatar-size: 16px;
  }

  .avatar {
    display: block;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
  }

  .initials,
  .icon {
    color: rgb(var(--avatar-color-r), var(--avatar-color-g), var(--avatar-color-b));
    font-size: calc(var(--avatar-size) / 2);
    margin-top: 2px;
  }

  .initials {
    font-family: LatoWebBlack, sans-serif;
  }

  &.link {
    cursor: pointer;
    border: 2px solid rgba(var(--avatar-color-r), var(--avatar-color-g), var(--avatar-color-b), 0.5);

    &:hover {
      border-color: rgba(var(--avatar-color-r), var(--avatar-color-g), var(--avatar-color-b), 1);
    }
  }
}

a .avatar-initials.link {
  border: 2px solid rgba(var(--avatar-color-r), var(--avatar-color-g), var(--avatar-color-b), 0.5);

  &:hover {
    border-color: rgba(var(--avatar-color-r), var(--avatar-color-g), var(--avatar-color-b), 1);
  }
}

.avatar-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-flex;
  /* Safari */
  display: flex;
  --border-color: #ffffff;

  li {
    margin-right: 3px;

    &.more {
      background: transparent !important;
      box-shadow: none;
      height: var(--avatar-size);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 3px !important;
    }
  }

  .avatar-initials {
    box-shadow: 0 0 0 2px var(--border-color);
  }

  &.stacked li {
    margin-left: -5px;
    margin-right: 0;

    &:first-child {
      margin-left: 0;
    }

    &:nth-child(1) {
      z-index: 5;
    }

    &:nth-child(2) {
      z-index: 4;
    }

    &:nth-child(3) {
      z-index: 3;
    }

    &:nth-child(4) {
      z-index: 2;
    }

    &:nth-child(5) {
      z-index: 1;
    }
  }
}

.online-badge {
  position: absolute;
  top: -2px;
  right: -5px;
  border: 2px solid #fff;
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;

  &.online {
    background: #28a745;
  }

  &.idle {
    background: #999;
  }
}

/* The switch - the box around the slider */

.switch {
  --switch-w: 32px;
  --switch-h: 20px;
  --slider-size: 12px;
  margin-bottom: -5px !important;
  padding-bottom: 5px;
  position: relative;
  display: inline-block;
  width: var(--switch-w);
  height: var(--switch-h);

  input {
    display: none;
  }
}

/* Hide default HTML checkbox */

/* The slider */

.slider {
  border: 2px solid #666;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: var(--switch-h);

  &:before {
    position: absolute;
    content: '';
    height: var(--slider-size);
    width: var(--slider-size);
    left: 2px;
    bottom: 2px;
    background-color: #666;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 50%;
  }
}

input:checked + .slider {
  background-color: #6c757d;
  border-color: #6c757d;

  &:before {
    background: #fff;
  }
}

.switch {
  &.success input:checked + .slider {
    background-color: #28a745;
    border-color: #28a745;
  }

  &.danger input:checked + .slider {
    background-color: #dc3545;
    border-color: #dc3545;
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(var(--slider-size));
  -ms-transform: translateX(var(--slider-size));
  transform: translateX(var(--slider-size));
}

/* The switch - the box around the slider */

// ==============================
// loading bars
$loading-bar-bg: #eee;
$loading-bar-indicator: #ddd;

.loading-bars,
.loading-bars::before,
.loading-bars::after {
  content: ' ';
  display: block;
  height: 10px;
  width: 100%;
  border-radius: 2px;
  background: linear-gradient(to right, $loading-bar-bg, $loading-bar-bg, $loading-bar-indicator, $loading-bar-bg, $loading-bar-bg);
  background-size: 600% 100%;
  animation: stripes-run 1s ease infinite;
}

.loading-bars {
  position: relative;
  margin-bottom: 35px;

  &.ng-animate {
    animation: none 0s;
  }

  &::before {
    position: absolute;
    top: 15px;
  }

  &::after {
    position: absolute;
    top: 30px;
  }
}

.loading-bars:last-child {
  margin-bottom: 30px;
}

@keyframes stripes-run {
  from {
    background-position: 100% 100%;
  }

  to {
    background-position: 0% 0%;
  }
}

.text-blink {
  animation: color-change 1s infinite;
}

@keyframes color-change {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.table-of-content {
  .toc-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #e4e4e4;
    margin-bottom: 1rem;

    .toc-item-left {
      margin-bottom: -5px;
      background: #fff;
      padding-right: 5px;
    }

    .toc-item-right {
      margin-bottom: -5px;
      background: #fff;
      padding-left: 5px;
    }

    &:last-child {
      margin-bottom: 0.5rem !important;
    }
  }

  &.table-of-content-lg .toc-item {
    margin-bottom: 1.5rem !important;
  }
}

.btn[disabled='disabled']:hover {
  background: initial !important;
}
