.img-manager {
  .img-list .card.selected .card-img-overlay.bg {
    background: rgba(0, 0, 0, 0.6);
  }

  .card {
    height: 100%;
  }

  .card-img-top {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

#img-manager-modal .list-group-item,
#content-publish-modal .list-group-item {
  padding: 6px 10px !important;
}

.modal-content {
  & > .publish-panels-fade.ng-enter {
    transition: 0.3s ease all;
    opacity: 0;
  }

  & > .publish-panels-fade.ng-enter.ng-enter-active {
    opacity: 1;
  }
}

.input-title {
  font-family: 'lato black', sans-serif;
  font-size: 2rem;
  padding: 0;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  width: 100%;
  height: 3.5rem;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #f6f6f6;
  }
}

.header-char-count {
  display: none;
}

// ckeditor
#post-detail-ckeditor {
  .cke_chrome {
    border: none !important;

    .cke_top {
      position: fixed;
      top: 100px;
      border: 0;
      background: transparent;
      left: 200px;
      border-left: 1px solid #e4e4e4;
      border-radius: 0;
    }

    .cke_inner,
    .cke_editable,
    .cke_editable body {
      background: transparent !important;
    }

    .cke_bottom {
      position: sticky;
      bottom: 0;
      border-color: #dfdfdf;
    }
  }

  .cke_balloon {
    border: 1px solid #aaa !important;
    border-radius: 0.25rem;
  }

  .cke_addtional_btn {
    position: sticky;
    top: 50px;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .cke_combo a.cke_combo_button,
  a.cke_button {
    border-color: transparent !important;
    border-radius: 0.25rem;
  }

  .cke_combo_on a.cke_combo_button,
  a.cke_button_on {
    background: #ddd !important;
  }

  // hide image button
  .cke_button__image {
    display: none !important;
  }

  // custom show/hide icons for content editor
  .cke_button__link,
  .cke_button__table,
  .cke_button__toc,
  .cke_button__horizontalrule {
    display: none !important;
  }

  .cke_button__dropdowninsert_label,
  .cke_button__dropdownmore_label {
    display: inline !important;
  }

  a.cke_button {
    .cke_button__dropdown_icon,
    .cke_button__dropdown_icon {
      display: none !important;
    }
  }
}
