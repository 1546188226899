.chat-footer {
  width: 100%;
  height: 49px;
  border-top: 1px solid #e4e4e4;

  .chat-input {
    display: block;
    border: none;
    box-shadow: none;
    outline: none;
    margin-left: 20px;
  }
}
.chat-body {
  height: calc(100% - 90px);
  overflow: auto;
  position: relative;

  .chat-messages {
    display: flex;
    flex-direction: column;
  }
}

.chat-typing {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease;

  &.show {
    height: 30px;
  }
}

.chat-body .chat-item {
  margin: 5px 1rem;
  text-align: right;

  &.hide-info {
    margin-bottom: 5px !important;
  }

  .chat-avatar {
    display: none;
  }

  .chat-content {
    max-width: 80%;
    display: inline-block;

    .chat-message {
      padding: 0.6rem 1rem;
      border-radius: 10px;
      border-bottom-right-radius: 0;
      text-align: left;
      background: #ffedd3;
      font-size: 13px;

      /* Snippet: break links, but not regular text */
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;

      /* Snippet: end */
    }

    .chat-time {
      font-size: 11px;
      color: #999;
    }

    &.sending {
      opacity: 0.5;
      filter: alpha(opacity = 0.5);
    }
  }

  &.left {
    display: flex;
    justify-content: flex-start;
    text-align: left;

    .chat-avatar {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 32px;
        height: 32px;
      }
    }

    &.is_new {
      .chat-message {
        background: #fffdf0 !important;
        border-color: #fde7a6 !important;
      }
    }

    .chat-content {
      margin-left: 8px;

      .chat-message {
        background: #fff;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 10px;
        border: 1px solid #dedede;
      }
    }
  }
}
