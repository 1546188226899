// right (owner)
.activities .activity-item {
  margin-bottom: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: right;

  &:first-child {
    margin-top: 1rem;
  }

  &.hide-info {
    margin-bottom: 5px !important;
  }

  .activity-avatar {
    display: none;
  }

  .activity-content {
    max-width: 80%;
    display: inline-block;

    .activity-message {
      padding: 0.6rem 1rem;
      border-radius: 10px;
      border-bottom-right-radius: 0;
      text-align: left;
      background: #edecf2;
      /* Snippet: break links, but not regular text */
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      /* Snippet: end */
    }

    .activity-time {
      font-size: 11px;
    }

    &.sending {
      opacity: 0.5;
      filter: alpha(opacity=.5);
    }

    &.system {
      .activity-message {
        background: #f9f9f9 !important;
        border: 1px dashed #e4e4e4 !important;
      }
      &.system-info {
        color: #999;
      }
      &.system-warning {
        color: var(--warning);
      }
      &.system-danger {
        color: var(--danger);
      }
    }
  }
}

// left (guests)
.activities .activity-item.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;

  .activity-avatar {
    display: inline;
  }

  .activity-content {
    margin-left: 8px;

    .activity-message {
      background: #fff;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 10px;
      border: 1px solid #dedede;
    }
  }

  &.is_new {
    .activity-message {
      background: #fffdf0 !important;
      border-color: #fde7a6 !important;
    }
  }
}

.activity-input {
  position: absolute;
  padding: 1rem;
  border: none;
  border-top: 1px solid #e4e4e4;
  width: 100%;
  box-shadow: none;
  outline: none;
}
